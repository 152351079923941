/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Chip from '@mui/joy/Chip';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import TwoSidedLayout from './TwoSidedLayout';
import ArrowForward from '@mui/icons-material/ArrowForward';

export default function Contact() {

  const handleClick = () => {
    window.location.href = "mailto:contact@menara.io?subject=Discover How We Can Help";
  };

  return (
    <TwoSidedLayout image={`${process.env.PUBLIC_URL}/contactus.webp`}>
      <Typography
        level="h1"
        sx={{
          fontWeight: 'xl',
          fontSize: 'clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)',
        }}
      >
        Have a project in mind or need more information? 
      </Typography>
      <Chip size="lg" variant="outlined" color="neutral">
        Your Success Partner
      </Chip>
      <Typography
        textColor="text.secondary"
        sx={{ fontSize: 'lg', lineHeight: 'lg' }}
      >
        Whether you're looking for a web app, mobile app, or enterprise solution, we deliver scalable, secure, and intuitive systems to support your business goals.      
      </Typography>
      <Typography
        textColor="text.secondary"
        sx={{ fontSize: 'lg', lineHeight: 'lg' }}
      >
        Ready to streamline your business? Contact us today to learn how we can help you adopt the right technology solutions.   
      </Typography>
      <Button
        size="lg"
        endDecorator={<ArrowForward />}
        sx={{ alignSelf: 'stretch', backgroundColor: 'gold' }}
        onClick={handleClick}
      >Contact Us</Button>
    </TwoSidedLayout>
  );
}
