import React from 'react';
import Typography from '@mui/joy/Typography';

export default function Header() {
  return (
    <Typography
        level="body-xs"
        sx={{
          position: 'absolute',
          top: '2rem',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Menara LLC" width={'100%'} />
      </Typography>
  );
};

