import Typography from '@mui/joy/Typography';

export default function Footer() {
  return (
    <Typography
        level="body-xs"
        sx={{
          position: 'absolute',
          bottom: '2rem',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        {`© ${new Date().getFullYear()} Menara LLC. All Rights Reserved.`}
      </Typography>
  );
};

