/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Chip from '@mui/joy/Chip';
import Typography from '@mui/joy/Typography';
import TwoSidedLayout from './TwoSidedLayout';

export default function About() {
  return (
    <TwoSidedLayout image={`${process.env.PUBLIC_URL}/aboutus.webp`}>
      <Typography
        level="h1"
        sx={{
          fontWeight: 'xl',
          fontSize: 'clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)'
        }}
      >
        Empowering your vision with digital solutions
      </Typography>
      <Chip size="lg" variant="outlined" color="neutral">
      Building Success Together
      </Chip>
      <Typography
        textColor="text.secondary"
        sx={{ fontSize: 'lg', lineHeight: 'lg' }}
      >
        Founded with a vision to empower businesses through technology, Menara LLC is a startup specializing in custom development, process automation and cloud adoption strategies.      
      </Typography>
      <Typography
        textColor="text.secondary"
        sx={{ fontSize: 'lg', lineHeight: 'lg' }}
      >
        At Menara, we believe that technology is the backbone of modern business success.      
      </Typography>
      <Typography
        textColor="text.secondary"
        sx={{ fontSize: 'lg', lineHeight: 'lg' }}
      >
        We bring together a team of expert architects and engineers passionate about turning complexity into simplicity and delivering high-impact solutions.      
      </Typography>
    </TwoSidedLayout>
  );
}
