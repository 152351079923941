/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Chip from '@mui/joy/Chip';
import Typography from '@mui/joy/Typography';
import TwoSidedLayout from './TwoSidedLayout';

export default function Services() {
  return (
    <TwoSidedLayout image={`${process.env.PUBLIC_URL}/services.webp`} reversed={true}>
      <Typography
        level="h1"
        sx={{
          fontWeight: 'xl',
          fontSize: 'clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)',
        }}
      >
        Personalized solutions to meet your unique need
      </Typography>
      <Chip size="lg" variant="outlined" color="neutral">
        Delivering Success for You
      </Chip>
      <Typography
        textColor="text.secondary"
        sx={{ fontSize: 'lg', lineHeight: 'lg' }}
      >
       Our team of expert architects and developers specializes in designing and building custom software solutions tailored to your exact needs.
      </Typography>
      <Typography
        textColor="text.secondary"
        sx={{ fontSize: 'lg', lineHeight: 'lg' }}
      >
        We provide a comprehensive suite of digital solutions to streamline your business, enhancing growth and efficiency through process automation.       
      </Typography>
      <Typography
        textColor="text.secondary"
        sx={{ fontSize: 'lg', lineHeight: 'lg' }}
      >
        Our services help boost productivity, minimize errors, and reduce operational costs.       
      </Typography>
    </TwoSidedLayout>
  );
}
